import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import ReactGA from 'react-ga';
import axios from 'axios';
import JSZip, { files } from 'jszip';
import backgroundBox from "./img/box-bg.png";
import "./App.css";

const TRACKING_ID = "G-HC4WFFYZEE";
ReactGA.initialize(TRACKING_ID);

const ImageUploader = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  
  const [previews, setPreviews] = useState([]);
  const [processedImages, setProcessedImages] = useState([]);
  const [processedData, setProcessedData] = useState([]);
  const [uploadMessage, setUploadMessage] = useState(''); // For development purposes
  const [imagesUploaded, setImagesUploaded] = useState(false);
  const [compressionProgress, setCompressionProgress] = useState(false);
  const [imagesCompressed, setImagesCompressed] = useState(false);

  const handleDrop = async (acceptedFiles) => {
    const minSize = 3000; // 3 KB in bytes
    const maxSize = 100 * 1024 * 1024; // 100 MB in bytes

    // Filter files by size and type
    const validFiles = acceptedFiles.filter(file => {
        const fileType = file.type;
        return file.size >= minSize && 
               (fileType === 'image/jpeg' || 
                fileType === 'image/png' || 
                fileType === 'image/svg+xml' || 
                fileType === 'image/gif');
    });

    if (validFiles.length === 0) {
        alert('All files were rejected because they are too small or not a supported format!');
        return;
    }

    if (validFiles.length !== acceptedFiles.length) {
        alert('Some files were rejected because they are too small or not a supported format!');
    }

    // Calculate total size of the files currently in previews and the new valid files
    const totalSize = previews.reduce((acc, file) => acc + file.file.size, 0) + 
                      validFiles.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > maxSize) {
        alert('Cannot upload files. Total size exceeds 100 MB.');
        return;
    }

    const newPreviews = validFiles.map(file => ({
        file,
        preview: URL.createObjectURL(file),
        size: file.size,
        speed: calculateLoadTime(formatKiloBytes(file.size)),
    }));

    setImagesUploaded(true);
    setPreviews([...previews, ...newPreviews]);
  };

  const deleteImage = (index) => {
    // Create a copy of the previews array
    const updatedPreviews = [...previews];
    
    // Remove the image at the specified index
    updatedPreviews.splice(index, 1);
    
    // Update the state with the new previews array
    setPreviews(updatedPreviews);

    // Checks if there are any files in previews before compression
    if (updatedPreviews.length <= 0) {
      setImagesUploaded(false);
    }
  };

  const compressImages = async () => {
    try {
      const formData = new FormData();
      previews.forEach((preview) => {
        formData.append('file', preview.file);
      });

      setCompressionProgress(true);

      const response = await axios.post('https://sendif-resize-img-4a1ac07d0ed5.herokuapp.com/resize', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setCompressionProgress(false);
      setImagesCompressed(true);
      setProcessedImages(response.data.processed_images);

      for (let i = 0; i < response.data.processed_images.length; i++) {
        const postSizeSpeed = {
          newSize: response.data.processed_images[i].new_file_size,
          newSpeed: calculateLoadTime(formatKiloBytes(response.data.processed_images[i].new_file_size)),
        }

        setProcessedData(current => [...current, postSizeSpeed]);
      }

      setUploadMessage('Images compressed successfully');
    } catch (error) {
      console.error('Error compressing images:', error);
      setUploadMessage('Error compressing images');
    }
  };

  const downloadSingleCompressedImage = (index) => {
    if (index >= 0 && index < processedImages.length) {
      const processedImage = processedImages[index];
      const originalFilename = processedImage.original_filename;
      
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:image/${getOriginalFileExtension(originalFilename)};base64,${processedImage.processed_image}`;
      downloadLink.download = 'Resize-Img_' + originalFilename;
      downloadLink.click();
    }
  };
  
  const downloadAllCompressedImages = () => {
    // Check if there's only one image to process
    if (processedImages.length === 1) {
      // Get the processed image
      const processedImage = processedImages[0];
      const originalFilename = processedImage.original_filename;
      const blob = base64ToBlob(processedImage.processed_image, `image/${getOriginalFileExtension(originalFilename)}`);
    
      // Create a link and trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = 'Resize-Img_' + originalFilename;
      downloadLink.click();
    } else {
      // There are multiple images, so proceed with zipping them
      const zip = new JSZip();
    
      processedImages.forEach((processedImage) => {
        const originalFilename = processedImage.original_filename;
        const blob = base64ToBlob(processedImage.processed_image, `image/${getOriginalFileExtension(originalFilename)}`);
    
        zip.file('Resize-Img_' + originalFilename, blob);
      });
    
      zip.generateAsync({ type: 'blob' }).then((content) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(content);
        downloadLink.download = 'Resize-Img.zip';
        downloadLink.click();
      });
    }
  };
  
  // Helper function to get the original file extension
  function getOriginalFileExtension(filename) {
    return filename.split('.').pop();
  }
  
  
  // Helper function to convert base64 to Blob for Zipping Files
  const base64ToBlob = (base64, mimeType) => {
    const byteCharacters = atob(base64);
    const byteArray = new Uint8Array(byteCharacters.length);
  
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }
  
    return new Blob([byteArray], { type: mimeType });
  };

  const formatBytes = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    // console.log("This is bytes: "+bytes);
  
    if (bytes === 0) return '0 Bytes';

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    const fileSizeKB = 100 * (bytes / Math.pow(1024, i)).toFixed(0) / 100 + ' ' + sizes[i]
    
    // console.log("This is filesizeKB: "+fileSizeKB);
    if (fileSizeKB == "NaN undefined") return '0 Bytes';

    return fileSizeKB;
  };

  function formatKiloBytes(bytes) {
    const kilobytes = bytes / 1024;
    return kilobytes.toFixed(3);
  }

  function calculateLoadTime(imageSizeKB) {
    const imageSizeMB = imageSizeKB / 1024; // Convert KB to MB
    const networkSpeedMbS = 10; // Constant network speed in Mb/s
    const loadTime = (imageSizeMB * 8) / networkSpeedMbS;
    const loadTimeSec = loadTime.toFixed(2)+'s';

    return loadTimeSec;
  }
  
  const estimateCompressionTime = (beforeFileSizeKB) => {
    // Calculate the estimated time in seconds
    const averageTimePerKB = 0.001;
    const estimatedTime = beforeFileSizeKB * averageTimePerKB;
    const formattedEstimatedTime = estimatedTime < 60
      ? estimatedTime.toFixed(2)
      : (estimatedTime / 60).toFixed(2)
  
    return formattedEstimatedTime;
  };

  function minusTwoArguments(first, second) {
    // Remove non-numeric characters and convert to numbers
    const firstNumber = parseFloat(first.replace(/[^\d.-]/g, ''));
    const secondNumber = parseFloat(second.replace(/[^\d.-]/g, ''));
  
    const result = firstNumber - secondNumber;
  
    return result.toFixed(2);
  }

  function CalcSingleSpeedPercent(first, second) {
    const firstNumber = parseFloat(first.replace(/[^\d.-]/g, ''));
    const secondNumber = parseFloat(second.replace(/[^\d.-]/g, ''));

    return (((firstNumber - secondNumber) / firstNumber) * 100).toFixed(1);
  };

  const CalcTotalSpeedPercent = () => {
    // for (let i = 0; i < previews.length; i++) {
    //   // console.log("This is previews speed: "+previews[i].speed);
    // }
    
    // for (let i = 0; i < processedData.length; i++) {
    //   // console.log("This is processedData speed: "+processedData[i].newSpeed);
    // }

    const totalOriginalSpeed = previews.reduce((acc, preview) => acc + parseFloat(preview.speed), 0);
    const totalNewSpeed = processedData.reduce((acc, data) => acc + parseFloat(data.newSpeed), 0);

    if (totalOriginalSpeed === 0) return 0;
    return (((totalOriginalSpeed - totalNewSpeed) / totalOriginalSpeed) * 100).toFixed(1);
  };

  const CalcTotalSpeedNum = () => {
    const totalOriginalSpeed = previews.reduce((acc, preview) => acc + parseFloat(preview.speed), 0);
    const totalNewSpeed = processedData.reduce((acc, data) => acc + parseFloat(data.newSpeed), 0);

    return (totalOriginalSpeed - totalNewSpeed).toFixed(1);
  };  

  const CalcTotalSizeNum = () => {
    const totalOriginalSize = previews.reduce((acc, preview) => acc + parseFloat(preview.size), 0);
    const totalNewSize = processedData.reduce((acc, data) => acc + parseFloat(data.newSize), 0);

    return formatBytes(totalOriginalSize - totalNewSize);
  };

  const clearImages = () => {
    setPreviews([]);
    setProcessedImages([]);
    setImagesUploaded(false);
    setUploadMessage('');
    setImagesCompressed(false);
    setCompressionProgress(false);
    setProcessedData([]);
  };

  const MenuBar = () => {
    return (
      <div className="menu-bar" >
        <div className="menu-left">
          <div className="menu-title" onClick={clearImages} style={{ cursor: 'pointer' }}>Email Image Resizer</div>
        </div>
        {/* <div className="menu-right">
          <a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
            <img
              src={`${process.env.PUBLIC_URL}/xlogo.png`}
              alt="Twitter Logo"
            />
          </a>
          <a href="#" className="social-icon" target="_blank" rel="noopener noreferrer">
            <img
              src={`${process.env.PUBLIC_URL}/iglogo.png`}
              alt="Instagram Logo"
            />
          </a>
        </div> */}
      </div>
    );
  };

  const FooterBar = () => {
    return (
      <div className="footer">
        <p className="copyright">&copy; 2023 Email Image Resizer. All rights reserved.</p>
      </div>
    );
  };

  const DropZoneAreaBefore = () => {
    return (
      <Dropzone onDrop={handleDrop} accept="image/*" multiple={true}>
        {({ getRootProps, getInputProps }) => (
          <div className="ImageUploader-DropDown-Box" {...getRootProps()} style={{ backgroundImage: `url(${backgroundBox})` }}>
            {/* <img className='box-bg' src={`${process.env.PUBLIC_URL}/box-bg.png`}/> */}
            <input {...getInputProps()} />
            <button className="ImageUploader-select-btn btn btn-primary" style={{ marginTop: '8vh' }}>
              Select Images
            </button>
            <p className="ImageUploader-drop-img">or drop images here</p>
            <img className="button-row" src={`${process.env.PUBLIC_URL}/button_row.png`}/>
          </div>
        )}
      </Dropzone>
      );
  };

  const DropZoneAreaAfter = () => {
    const handleButtonClick = (event) => {
      // Prevent the click event from reaching the Dropzone
      event.stopPropagation();
    };
    
    return (
      <Dropzone onDrop={handleDrop} accept="image/*" multiple={true}>
        {({ getRootProps, getInputProps }) => (
          <div className="ImageUploader-DropDown-Box-After" {...getRootProps()}  style={{ backgroundImage: `url(${backgroundBox})` }}>
            <input {...getInputProps()} />
            
            <div className="row">
              <div className="col">
                <button className="ImageUploader-add-btn btn btn-outline-primary">
                  Add Images
                </button>
              </div>
              <div className="col">
                <p className="ImageUploader-drop-para">or drop images here</p>
              </div>
            </div>
            
            <div className="ImageUploader-thumbnails">
                {previews.map((preview, index) => (
                  <div key={index} className="ImageUploader-thumbnail-container"
                    onClick={handleButtonClick}>
                    
                    <div className="row">
                      <div className="delete-btn-container">
                        <button
                          className="delete-btn"
                          onClick={() => deleteImage(index)}
                        >
                          <img
                            src={`${process.env.PUBLIC_URL}/trash.png`}
                            alt="Delete"
                            className="delete-btn-img"/>
                        </button>
                      </div>
                      <div className="col-md-12">
                        <img
                          className="ImageUploader-thumbnail"
                          src={preview.preview}
                          alt={`Thumbnail ${index + 1}`}
                        />
                      </div>
                    </div>
                    
                    <div className="size-row row">
                      <div className="col">
                        <img className="thumbnail-size-img" src={`${process.env.PUBLIC_URL}/picture.jpg`}/>
                      </div>
                      <div className="col">
                        <p className="ImageUploader-thumbnail-size">{formatBytes(preview.size)}</p>
                      </div>
                    </div>
                    
                    <div className="timer-row row">
                    <div className="col">
                       <img className="timer-img" src={`${process.env.PUBLIC_URL}/timer.jpg`}/>
                      </div>
                      <div className="col">
                        <p className="ImageUploader-thumbnail-timer">{preview.speed}</p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div onClick={handleButtonClick}>
                <button className="compress-images-btn btn btn-primary" onClick={compressImages}>
                  Compress Images
                </button>
              </div>
          </div>
        )}
      </Dropzone>
    );
  };

  function ProgressBar({ estCompressionTime }) {
    const [progress, setProgress] = useState(0);
    const totalMilliseconds = estCompressionTime * 1000;
  
    useEffect(() => {
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          const increment = totalMilliseconds * 0.01;
          if (prevProgress < totalMilliseconds) {
            return prevProgress + increment;
          } else {
            clearInterval(interval);
            return totalMilliseconds;
          }
        });
      }, totalMilliseconds * 0.01);
  
      return () => clearInterval(interval);
    }, [totalMilliseconds]);
  
    return (
      <progress className='progress-bar' value={progress / 1000} max={estCompressionTime} />
    );
  }

  const LoadingZone = () => {
    return (
      <div className="ImageUploader-dl-Box-After" style={{ backgroundImage: `url(${backgroundBox})`}}>
        <p className="ImageUploader-dl-para-compressing">Compressing...</p>
        <div className="ImageUploader-loading-thumbnails">
          {previews.map((preview, index) => (
              <div key={index} className="ImageUploader-thumbnail-container">
                  <img
                    className="ImageUploader-loading-thumbnail"
                    src={preview.preview}
                    alt={`Thumbnail ${index + 1}`}
                  />

              <ProgressBar estCompressionTime={estimateCompressionTime(formatKiloBytes(preview.file.size))} />

            </div>
          ))}
        </div>
      </div>
    )
  };

  const DownloadZone = () => {
    return (
      <div className="ImageUploader-dl-Box-After"  style={{ backgroundImage: `url(${backgroundBox})` }}>
        <div className="ImageUploader-dl-para">
          <p className="ImageUploader-dl-para-child">
            <span>Your images now load </span>
            <span style={{fontWeight:'bold'}}> <CalcTotalSpeedPercent/>% </span> 
            <span> faster on email!</span>
          </p>
        </div>

        <div className="ImageUploader-dl-thumbnails">
          {processedImages.map((processedImage, index) => (
              <div key={index} className="ImageUploader-thumbnail-container">

              <div className="row">
                <div className="delete-btn-container">
                  <button
                    className="dl-btn"
                    onClick={() => downloadSingleCompressedImage(index)}
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/download.png`}
                      alt="Delete"
                      className="dl-btn-img"
                    />
                  </button>
                </div>

                <div className="col-md-12">
                  <img
                    className="ImageUploader-dl-thumbnail"
                    src={`data:image/png;base64,${processedImage.processed_image}`}
                    alt={`Thumbnail ${index + 1}`}
                  />
                  <div className="thumbnail-stats">
                    <span>-{minusTwoArguments(previews[index].speed, processedData[index].newSpeed)}s</span>
                    <span>-{CalcSingleSpeedPercent(previews[index].speed, processedData[index].newSpeed)}%</span>
                  </div>
                </div>
              </div>
              
              <div className="timer-compare-row">
                <div>
                  <img className="timer-compare-img" src={`${process.env.PUBLIC_URL}/timer.jpg`} />
                  <p className="ImageUploader-thumbnail-prev-timer">{previews[index].speed}</p>
                </div>

                <div>
                  <img className="arrow-compare-timer" src={`${process.env.PUBLIC_URL}/arrow.png`} />
                  <p className="ImageUploader-thumbnail-compare-timer">{calculateLoadTime(formatKiloBytes(processedImage.new_file_size))}</p>
                </div>
              </div>
              
              <div className="size-compare-row">
                <div>
                  <img className="thumbnail-compare-size-img" src={`${process.env.PUBLIC_URL}/picture.jpg`} />
                  <p className="ImageUploader-thumbnail-prev-size">{formatBytes(previews[index].size)}</p>
                </div>

                <div>
                  <img className="arrow-compare-size" src={`${process.env.PUBLIC_URL}/arrow.png`} />
                  <p className="ImageUploader-thumbnail-compare-size">{formatBytes(processedImage.new_file_size)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="calc-total-row">
          <div className="calc-total">
            <p className="num"><CalcTotalSpeedPercent/>%</p>
            <p> Faster</p>
          </div>
          <div className="calc-total">
            <p className="num">-<CalcTotalSpeedNum/>s</p>
            <p> Load Speed</p>
          </div>
          <div className="calc-total">
            <p className="num"><CalcTotalSizeNum/></p>
            <p> Saved</p>
          </div>
        </div>

        <div>
          <button className="dl-images-btn btn btn-primary" onClick={downloadAllCompressedImages}>
            Download All
          </button>
          <button className="resize-more-images-btn btn btn-outline-primary" onClick={clearImages}>
            Compress More Images
          </button>
        </div>
      </div>
    );
  }
  
  return (
    // <body style={{ backgroundImage: `url(${background})` }}>
    <body>
      <div>
        <MenuBar />
        <div className="title-row">
          <h1 className="Body-Title">Compress Image For Email</h1>
          <p className="Body-Txt">Optimise JPEG, PNG and GIF for super fast <br></br>load times on email.</p>
        </div>
        
        {compressionProgress ? (
          <div className="ImageUploader-dropzoneAfter">
            <LoadingZone />
          </div>
        ) : imagesCompressed ? (
          <div className="ImageUploader-dropzoneAfter">
            <DownloadZone />
          </div>
        ) : (
          <div>
            {!imagesUploaded ? (
              <div className="ImageUploader-dropzone">
                <DropZoneAreaBefore />
              </div>
            ) : (
              <div className="ImageUploader-dropzoneAfter">
                <DropZoneAreaAfter />
              </div>
            )}
          </div>
        )}

        {/* Development Testing Messages */}
        {/* {uploadMessage && (
          <div>
            <p className="ImageUploader-upload-message">{uploadMessage}</p>
          </div>
        )} */}
      <FooterBar></FooterBar>
      </div>
    </body>
  );
};

export default ImageUploader;
